<template>
  <b-card>
    <!-- form -->
    <b-row>
      <b-col cols="10">
        <h2 class="text-primary"> </h2>
      </b-col>
      <b-col cols="2">
        <b-badge variant="warning" class="badge-glow" v-if="isUpdated">
          Not Saved
        </b-badge>
      </b-col>
    </b-row>
    <b-form>
      <b-row>
        <!-- bio -->
        <b-col cols="12"  class="mt-2">
          <b-form-group label="" label-for="bio-area">
            <div class="certificate-container">
      <!-- Menu section -->
      <div class="menu">
        <h2 class="logo"></h2>
      <h4 class="text-whitee mb-1">Document Size</h4>
          <div class="playgound-size">
            <div>
              <label for="">H</label>
              <input type="text" id="playground-height" value="500" />
            </div>
            <div>
              <label for="">W</label>
              <input type="text" id="playground-width" value="700" />
            </div>
          </div>
          <br/>
   <button type="button" id="playground-btn">Submit</button>
         

        <h4 class="text-whitee mb-1">Change text</h4>
        <input type="text" id="input-text" /> <br />
        <div class="text-change-group">
          <div class="my-2">
            <span class="text-whitse">Color</span>:
            <input type="color" id="text-color" />
          </div>
          <div class="my-2">
            <label for="">S</label>
            <input type="text" id="font-size-input" style="width: 50px" />
          </div>
          <button   type="button"  id="text-delete-btn" class="delete-btn"> 
              <feather-icon
                  icon="TrashIcon"
                  size="18"
                />
          </button>
        </div>
        <div class="select">
          <p class="select-menu text-whites">Select Font</p>
          <ul class="select__options d-none">
            <li>
              <span class="courier"> Times, serif</span>
            </li>
            <li><span class="arial">Arial, Helvetica, sans-serif</span></li>
            <li>
              <span class="verdana">Verdana, Geneva, Tahoma, sans-serif</span>
            </li>
            <li>
              <span class="impact"
                >Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif</span
              >
            </li>
          </ul>
        </div>

        <button  type="button"  id="update-text-btn" style="margin-top: 20px">
          Update text
        </button>
        <h3 class="title text-whitse" style="margin-top: 40px">
          Update image
          <button  type="button"  class="delete-btn" id="image-delete-btn" style="float: right">
           <feather-icon
                  icon="TrashIcon"
                  size="18"
                />
          </button>
        </h3>
        <label for="height">H</label>
        <input type="text" id="height" />
        <label id="width-label" for="height">W</label>
        <input type="text" id="width" />
        <br />
        <button  type="button"  id="update-img">Update Image</button>
        <!-- <button class="download-btn">Download</button> -->
        <!-- <a id="download">Download</a> -->
      </div>
      <!-- playground -->
      <div id="playground-container">
        <div class="actions md-3  match-height">
          <div class="add-name-container">
            <h3 class="title">Add Text</h3>
            <button  type="button"  id="add-text">Add text</button>
          </div>

          <div class="add-image-container  md-3">
            <h3 class="title">Add Image</h3>
             <label class="custom-file-upload">
            <input
              type="file"  size="60" style="height:10px;"
              id="img"
              class="custom-file-input button"
              accept="image/png, image/jpg"
            />Upload
              </label>
          </div>

          <div class="add-background-image-container  md-3">
            <h3 class="title">Background Image</h3>
            <label class="custom-file-upload">
            <input
              type="file"   size="60"  style="height:10px;"
              id="bg-img"
              class="custom-file-input "
              accept="image/png, image/jpg"
            />Upload
            </label>
          </div>
        
        </div>
        <div class="playground-content">
          <div class="playground"></div>
        </div>
      </div>
    </div>
            <a href="" id="download-btn"
              ><i class="fa fa-download fa-2x"></i
            ></a>
          </b-form-group>
        </b-col>
        <!--/ bio -->

        <!-- phone -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            :disabled="isLoading ? true : false"
            @click.prevent="sendInformation"
          >
            Save changes
          </b-button>
          
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormTextarea,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import { quillEditor } from "vue-quill-editor";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";
import { integer } from "@/@core/utils/validations/validations";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
    flatPickr,
    Cleave,
    quillEditor,
    BBadge,
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      required: true,
      default: () => "",
    },
    id: {
      type: integer,
      required: true,
      default: () => "",
    },
    informationData: {
      type: String,
      required: false,
      default: () => "",
    },
    fieldInformation: {
      type: String,
      required: true,
      default: () => "",
    },
  },
  data() {
    return {
      countryOption: ["USA", "India", "Canada"],
      snowOption: {
        theme: "snow",
      },
      isLoading: false,
      isUpdated: false,

      localOptions: JSON.parse(JSON.stringify(this.informationData ?? "")),

      clevePhone: {
        phone: true,
        phoneRegionCode: "US",
      },
    };
  },
  methods: {
    sendInformation() {
      console.log("update course  is launching with id : " + this.id);

      this.isLoading = true;
      var textContent=document.querySelector(".playground-content").innerHTML;


      axios({
        method: "put",
        url: `/lms/admin/course/updatecertificatedetail/${this.id}`,
        data: {
          info: this.fieldInformation,
          content: textContent,
        },
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
      return;
    },

    resetForm() {
      this.localOptions = JSON.parse(
        JSON.stringify(this.informationData ?? "")
      );
    },
    onEditorBlur(quill) {
      //    console.log('editor blur!', quill)
    },
    onEditorFocus(quill) {
      //   console.log('editor focus!', quill)
    },
    onEditorReady(quill) {
      //     console.log('editor ready!', quill)
    },
    onEditorChange({ quill, html, text }) {
      //     console.log('editor change!', quill, html, text)
      this.isUpdated = true;
      this.content = html;
    },
  },
  computed: {
    editor() {
      //   return this.$refs.myQuillEditor.quill
    },
  },
  mounted() {
    var data = JSON.parse(JSON.stringify(this.informationData ?? ""));
  const playground = document.querySelector(".playground");
const addTextBtn = document.querySelector("#add-text");
const textInput = document.querySelector("#input-text");
const fontSizeInput = document.querySelector("#font-size-input");
const updateTextBtn = document.querySelector("#update-text-btn");
const textColor = document.querySelector("#text-color");

const select = document.querySelector(".select");
const selectOptionsContainer = select.querySelector(".select__options");
const selectOptions = document.querySelectorAll(".select__options li");
const selectText = document.querySelector(".select > p");

let holder = {};
let selected = {};

addTextBtn.addEventListener("click", () => {
  holder.obj = document.createElement("div");
  holder.obj.textContent = "{{NAME}}";
  holder.obj.style.position = "absolute";
  holder.obj.style.fontSize = "30px";
  holder.obj.style.zIndex = 1;
  holder.obj.style.cursor = "move";
  holder.obj.ondblclick = change;

  dragger(holder.obj);
  playground.appendChild(holder.obj);
});

// under observation
function dragger(element) {
  let pos = {};
  element.onmousedown = dragMouse;

  function dragMouse(e) {
    pos.x = e.clientX;
    pos.y = e.clientY;

    document.onmouseup = function () {
      document.onmouseup = null;
      document.onmousemove = null;
    };
    document.onmousemove = function (e) {
      pos.ux = pos.x - e.clientX;
      pos.uy = pos.y - e.clientY;
      pos.x = e.clientX;
      pos.y = e.clientY;

      element.style.left = element.offsetLeft - pos.ux + "px";
      element.style.top = element.offsetTop - pos.uy + "px";
    };
  }
}

//observation till this point
function change(e) {
  textInput.value = e.target.textContent;
  fontSizeInput.value = e.target.style.fontSize.slice(0, -2);
  selected.obj = e.target;
}
updateTextBtn.addEventListener("click", function () {
  selected.obj.textContent = textInput.value;
  selected.obj.style.fontFamily = selected.obj.fontFamily;
  selected.obj.style.fontSize = fontSizeInput.value + "px";
  selected.obj.style.color = `${textColor.value}`;
});

select.addEventListener("click", function () {
  selectOptionsContainer.classList.toggle("d-none");
});
selectOptions.forEach((option) => {
  option.addEventListener("click", function () {
    selected.obj.fontFamily = option.querySelector("span").textContent;

    document.querySelector(".select-menu").textContent =
      option.querySelector("span").textContent;
  });
});

//add background image
const bgImg = document.querySelector("#bg-img");
let uploadedImg = "";
bgImg.addEventListener("change", function () {
  const reader = new FileReader();
  reader.addEventListener("load", function () {
    uploadedImg = reader.result;
    playground.style.backgroundImage = `url(${uploadedImg})`;
    // style the playground
    playground.style.height = "500px";
    playground.style.width = "700px";
    playground.style.backgroundSize = "contain";
    playground.style.backgroundPosition = "center";
     playground.style.backgroundRepeat = "no-repeat";
  });
  reader.readAsDataURL(this.files[0]);
});

//working with image
const image = document.querySelector("#img");
const heightImg = document.querySelector("#height");
const widthImg = document.querySelector("#width");
const updateImageBtn = document.querySelector("#update-img");

let updatedImage = "";
const selectedImg = {};
image.addEventListener("change", function () {
  const imageReader = new FileReader();
  imageReader.addEventListener("load", function () {
    updatedImage = imageReader.result;
    const img = document.createElement("div");
    img.style.backgroundImage = `url(${updatedImage})`;

    // style
    img.style.height = "100px";
    img.style.width = "100px";
    img.style.backgroundSize = "cover";
    img.style.backgroundPosition = "top";
    img.style.position = "absolute";
    img.ondblclick = changeImage;
    dragger(img);
    playground.appendChild(img);
  });
  imageReader.readAsDataURL(this.files[0]);
});
function changeImage(e) {
  selectedImg.obj = e.target;
  heightImg.value = selectedImg.obj.style.height.slice(0, -2);
  widthImg.value = selectedImg.obj.style.width.slice(0, -2);
}
updateImageBtn.addEventListener("click", (e) => {
  selectedImg.obj.style.height = heightImg.value + "px";
  selectedImg.obj.style.width = widthImg.value + "px";
});

// Download btn functionalities
const a = document.querySelector("#download-btn");

a.addEventListener("click", () => {
  a.download = "certificate.html";
  // a.href =
  //   "data:text/html," +
  //   document.getElementById("playground-container").innerHTML;
  a.href =
    "data:text/html," + document.querySelector(".playground-content").innerHTML;
});

const textDeleteBtn = document.querySelector("#text-delete-btn");
const imageDeleteBtn = document.querySelector("#image-delete-btn");
textDeleteBtn.addEventListener("click", function () {
  selected.obj.parentElement.removeChild(selected.obj);
});

imageDeleteBtn.addEventListener("click", function () {
  selectedImg.obj.parentElement.removeChild(selectedImg.obj);
});
const playgroundHeightInput = document.querySelector("#playground-height");
const playgroundWidthInput = document.querySelector("#playground-width");
const playgroundSizeBtn = document.querySelector("#playground-btn");

playgroundSizeBtn.addEventListener("click", () => {
  playground.style.height = +playgroundHeightInput.value + "px";
  playground.style.width = +playgroundWidthInput.value + "px";
});


  },


};
</script>

<style scoped>
.certificate-container {
  display: flex;
}
/* Reusable  */
.title {
  color: #213e7b;
  margin-bottom: 10px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.logo {
  font-size: 30px;
  color: #00afdd;
  margin-bottom: 30px;
}
/* menu  */
.menu {
  flex: 0 0 20%;
  background-color: rgb(252, 252, 252);
  border-right: 1px solid #f1f1f1;
  color: whtie;
  min-height: 100vh;
  padding: 10px 20px;
}
.button {
  border: none;
  background-color: #00afdd;
  color: white;
  padding: 10px 30px;
  margin-bottom: 15px;
  cursor: pointer;
}
button {
  border: none;
  background-color: #00afdd;
  color: white;
  padding: 10px 30px;
  margin-bottom: 15px;
  cursor: pointer;
}
/* playground  */
#playground-container {
  flex: 1;

  /* z-index: -1; */
}
.playground {
  height: 500px;
  width: 700px;
  border: 1px solid orange;
  position: relative;

  margin-top: 2%;
  margin-left: 2%;
}
.courier {
  font-family: "Times New Roman", Times, serif;
}
.arial {
  font-family: Arial, Helvetica, sans-serif;
}
.verdana {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.impact {
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
.select {
  position: relative;
  /* height: 10rem; */
}
.select__options {
  background-color: #5b87f0;
  z-index: 2;
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
}
.select__options li {
  margin: 10px 0;
}
.select__options li:hover {
  cursor: pointer;
  background-color: rgb(65, 174, 233);
}
.d-none {
  display: none;
}
.image {
  background-size: cover;
  background-position: top;
  position: absolute;
}
.download-btn {
  width: 100%;
  padding: 10px 0;
  display: block;
  margin: 40px 0;
  background-color: #00afdd;
  cursor: pointer;
  color: white;
  font-size: 18px;
  border: none;
}

 
.custom-file-upload {
  width: 100%;
  padding: 10px 0;
  display: block;
 
  background-color: #00afdd !important;
  cursor: pointer;
  color: white !important;;
  font-size: 18px;
  border: none;
}
input[type="file"] {
 display:none;
}
label{
  text-align:center;
  font-size:11pt !important;
}
.select-menu {
  cursor: pointer;
  margin: 10px 0;
  border: 1px solid #f1f1f1;
  padding: 5px 15px;
}

/* Now for testing  */
.text-white {
 color: white;
}
input[type="text"] {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #00afdd;
  color: rgb(99, 96, 96);
  width: 100%;
}
input:focus {
  outline: none;
}
label {
  color: rgb(118, 117, 117);
  margin-right: 10px;
}
#height,
#width {
  width: 50px;
  margin-bottom: 2rem;
}

#width-label {
  margin-left: 30px;
}
/* .add-background-image-container {
  position: absolute;
  top: 40px;
  right: 20px;
}
.add-image-container {
  position: absolute;
  top: 40px;
  left: 50%;
}
.add-name-container {
  position: absolute;
  top: 40px;
  left: 25%;
} */
.my-2 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.mb-1 {
  margin-bottom: 10px;
}
#download-btn {
  position: absolute;
  right: 150px;
  bottom: 50px;
  color: #00afdd;
  padding: 20px;
  border-radius: 50%;
  transition: all 0.2s;
}
#download-btn:hover {
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.25);
}

.text-change-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.delete-btn {
  
  padding: 2px;
  border-radius: 15%;
  border: 1px solid tomato;
  color: tomato;
  background-color: transparent;
}
.delete-btn:hover {
  background-color: tomato;
  color: white;
}
.actions {
  display: grid;
  grid-gap: 1rem;
  margin-left: 2%;
  grid-template-columns: repeat(3, 1fr);
}
.playgound-size {
  display: flex;
  align-items: center;
}

.playgound-size input,
.playgound-size label {
  color: black;
}
.playgound-size input {
  width: 50px;
  margin-right: 1rem;
}

.playgound-size button {
  padding: 10px;
}
.custom-file-input {
  margin-bottom: 10px;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: "Add image";
  display: inline-block;
   border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.custom-bg-input::before {
  content: "Add Background Image";
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
/* hide no chosen file  */
#img {
  width: 20px;
  
}


</style>
